<template>
  <div>
    <s-drawer
      :visible="isShow"
      no-header
      type="full"
      direction="rtl"
    >
      <div
        class="mshe-z-transform1 transform-fixed mshe-bg-container shipping-report-form"
      >
        <s-title-nav :title="title">
          <s-title-nav-item
            slot="preblock"
            class="j-icon-back"
            icon-class="suiiconfont sui_icon_nav_back_24px"
            @click="handleBack"
          />
        </s-title-nav>
        <section class="shipping-report-form__content">
          <div
            v-if="radioGroup.length"
            class="shipping-report-form__content-module"
          >
            <div class="shipping-report-form__content-tit">
              <span class="require">{{ template(title, language.SHEIN_KEY_PWA_29044) }}
              </span>
              <i
                class="suiiconfont sui_icon_doubt_15px_2"
                @click="openFraudulenceDrawer = true"
              ></i>
            </div>
            <div class="shipping-report-form__content-context">
              <s-radio-group
                v-model="formData.subType"
                @change="handleTypeChange"
              >
                <s-radio
                  v-for="item in radioGroup"
                  :key="item.title"
                  :label="item.value"
                >
                  {{ item.title }}
                </s-radio>
              </s-radio-group>
            </div>
            <div
              v-if="showTypeError"
              class="shipping-report-form__content-error"
            >
              <i class="suiiconfont sui_icon_caution_12px_2"></i>
              <span>{{ template(title, language.SHEIN_KEY_PWA_29043) }}</span>
            </div>
          </div>

          <div class="shipping-report-form__content-module">
            <div class="shipping-report-form__content-tit">
              <span class="require">{{ language.SHEIN_KEY_PWA_28945 }} </span>
            </div>
            <div class="shipping-report-form__content-context">
              <Field
                ref="contentInput"
                v-model.trim="formData.content"
                :placeholder="language.SHEIN_KEY_PWA_28946"
                class="shipping-report-form__content-textarea"
                label=""
                :rows="6"
                input-mode="textarea"
                max-length="2000"
                show-word-limit
                focus
                @input="handleContentInput"
              />
            </div>
            <div
              v-if="showContentError"
              class="shipping-report-form__content-error"
            >
              <i class="suiiconfont sui_icon_caution_12px_2"></i>
              <span>{{ language.SHEIN_KEY_PWA_28947 }}</span>
            </div>
          </div>

          <div class="shipping-report-form__content-module">
            <div class="shipping-report-form__content-tit">
              <span>{{ language.SHEIN_KEY_PWA_28948 }} </span>
            </div>
            <div class="shipping-report-form__content-context">
              <Field
                v-model.trim="formData.orderNumber"
                class="shipping-report-form__content-input"
                label=""
              />
            </div>
          </div>
          <div
            class="shipping-report-form__content-agree shipping-report-form__content-module"
          >
            <div class="shipping-report-form__content-context">
              <s-checkbox v-model="isAgree">
                {{ language.SHEIN_KEY_PWA_28949 }}
              </s-checkbox>
            </div>
          </div>

          <s-button
            class="shipping-report-form__content-submit"
            :type="['primary']"
            :loading="showBtnLoading"
            @click="handleBtnClick"
          >
            {{ language.SHEIN_KEY_PWA_28953 }}
          </s-button>
        </section>
      </div>
    </s-drawer>
    <StatementDrawer
      v-model="openStatementDrawer"
      :language="props.language"
      @agree="handleAgree"
    />
    <FraudulenceDrawer
      v-model="openFraudulenceDrawer"
      :fraudulenceList="radioGroup"
      :language="props.language"
      :title="title"
    />
    <ReportResult
      v-if="resultDrawer"
      v-model="resultDrawer"
      :language="props.language"
    />
  </div>
</template>

<script setup>
/**
 * @component 投诉抽屉
 */

import { template } from '@shein/common-function'
import { computed, ref, reactive } from 'vue'
import {
  Field,
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
import ReportResult from './ReportResult'
import StatementDrawer from './StatementDrawer'
import FraudulenceDrawer from './FraudulenceDrawer'
import schttp from 'public/src/services/schttp'
import { useFilterList } from './hooks'
const {
  REPORT_URL: { SA_REPORT_URL },
  SiteUID
} = gbCommonInfo
const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => ({})
  },
  skc: {
    type: String,
    default: ''
  },
  curItem: {
    type: Object,
    default: () => ({})
  },
  storeCode: {
    type: Number,
    default: 0
  },
  storeStoreType: {
    type: Number,
    default: 0
  },
  businessModel: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(['input'])
const isShow = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('input', val)
  }
})
const title = computed(() => props.curItem?.title ?? '')
// const radioGroup = computed(() => props.curItem?.children ?? [])
const { result: radioGroup } = useFilterList(props.curItem?.children)
const handleBack = () => {
  isShow.value = false
}

const openFraudulenceDrawer = ref(false)
const resultDrawer = ref(false)

// 表单提交数据
const formData = reactive({
  subType: '',
  content: '',
  orderNumber: ''
})
const showBtnLoading = ref(false)
// 单选
const handleTypeChange = () => {
  showTypeError.value = false
}
// 文本框
const handleContentInput = val => {
  if (val && val.length >= 30) {
    showContentError.value = false
  }
}
// 协议
const isAgree = ref(false)
const openStatementDrawer = ref(false)
// 校验
const showTypeError = ref(false)
const showContentError = ref(false)

const handleAgree = () => {
  submitForm()
}

// 提交成功
const submitSuccess = () => {
  showBtnLoading.value = false
  resultDrawer.value = true
  // isShow.value = false
}

// 提交失败
const submitFail = () => {
  showBtnLoading.value = false
  this.$toast({
    content: props.language.SHEIN_KEY_PWA_18481 || 'report fail',
    duration: 2000,
  })
}

// 监察系统接口
const goodsInfringementHttp = async () => {
  sa(
    'error',
    `${SA_REPORT_URL}/goodsInfringement`,
    {
      content: formData.content,
      order_number: formData.orderNumber,
      site_uid: SiteUID,
      skc: props.skc,
      source: 1,
      type: Number(props.curItem.type)
    },
    { onlyBaseData: true }
  )

  submitSuccess()
}
// 简易客服接口
const simpleReportHttp = async () => {
  const { businessModel, skc, storeCode } = props
  const { subType, content, orderNumber } = formData
  const secondTheme = radioGroup.value.find(
    item => item.value === subType
  )?.title
  const params = {
    storeStoreType: props.storeStoreType,
    business_model: businessModel,
    first_theme: title.value,
    second_theme: secondTheme,
    comment: content,
    order_no: orderNumber,
    shunt_store_code: String(storeCode),
    skc,
  }
  try {
    await schttp({
      url: `/api/robot/gwotk/simpleReport/create`,
      method: 'POST',
      data: params
    })
    submitSuccess()
  } catch (err) {
    submitFail(err.errMsg)
  }
}

// 传统客服接口
const traditionReportHttp = async () => {
  const { businessModel, skc, storeCode } = props
  const { subType, content, orderNumber } = formData
  const secondTheme = radioGroup.value.find(
    item => item.value === subType
  )?.title
  const params = {
    storeStoreType: props.storeStoreType,
    business_model: businessModel,
    first_theme: title.value,
    second_theme: secondTheme,
    comment: content,
    order_no: orderNumber,
    shunt_store_code: String(storeCode),
    skc,
  }
  try {
    await schttp({
      url: `/api/robot/gwotk/simpleReport/create`,
      method: 'POST',
      data: params,
    })
    submitSuccess()
  } catch (err) {
    submitFail(err.errMsg)
  }
}

// 提交
const submitForm = async () => {
  //todo type暂定 后续根据接口调整
  // 当主题为 Fraudulent sellers下的时候，需先根据storeCodes字段来判断是走“传统平台”还是“简易平台”
  // 当主题为Prohibited products和Offensive content时，调商品监察系统接口
  showBtnLoading.value = true
  if (props.curItem.type === 3) {
    // 1是传统平台,4是简易平台，不是1都按4来处理
    if (props.storeStoreType === 1) {
      traditionReportHttp()
    } else {
      simpleReportHttp()
    }
  } else if ([1, 2].includes(props.curItem.type)) {
    goodsInfringementHttp()
  }
}
const contentInput = ref(null)
const handleBtnClick = async () => {
  if (radioGroup.value.length && !formData.subType) {
    showTypeError.value = true
  }
  if (!formData.content || formData.content.length < 30) {
    contentInput.value.focus()
    return (showContentError.value = true)
  }
  if (showTypeError.value || showContentError.value) return

  if (!isAgree.value) {
    openStatementDrawer.value = true
    return
  }

  if (showBtnLoading.value) return

  submitForm()
}
</script>

<style lang="less">
.shipping-report-form {
  background-color: #fff !important; /* stylelint-disable-line declaration-no-important */
  &__header {
    h4 {
      /* rw:begin */
      font-family: Adieu;
    }
    .sui_icon_nav_back_24px {
      /* rw:begin */
      .font-dpr(
        48px
      ) !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  &__content {
    padding: 0.43rem;
    height: 100%;
    .S-radio {
      width: 100%;
      color: #000;
      font-size: 0.37333rem;
      padding-top: 0.4rem;
    }
  }
  &__content-module {
    padding-bottom: 0.5rem;
  }
  &__content-tit {
    // display: flex;
    align-items: center;
    color: #000;
    font-size: 0.37333rem;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 0.2rem;
    .require {
      &::after {
        content: '*';
        display: inline-block;
        color: #bf4123;
        font-size: 0.37333rem;
        font-weight: 700;
      }
    }
    > span {
      padding-right: 0.1rem;
    }
    .suiiconfont {
      color: #959595;
    }
  }
  &__content-context {
    color: #000;
    font-size: 0.37333rem;
    line-height: normal;
    .kgVtzC .S-checkbox__label {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.32rem;
    }
  }
  &__content-textarea {
    .S-Field__left {
      font-size: 0.37333rem;
      box-sizing: border-box;
      // padding-left: 0;
      // padding-right: 0;
      // padding-top: 0;
      padding: 0 0.32rem;
      height: 3.81333rem;
      border: 1px solid #ccc !important; /* stylelint-disable-line declaration-no-important */
      textarea {
        height: 3.0333rem;
        background: none;
      }
    }
    .S-Field {
      left: 0 /*rtl:ignore*/;
      width: 100%;
      display: none;
    }
    .S-Field__inputbox {
      margin-top: 0.2133rem;
    }
    .S-Field__counter {
      color: #bbb;
      font-size: 0.37333rem;
    }
    &.focus::after {
      display: none;
    }
  }
  &__content-input {
    .S-Field__left {
      font-size: 0.37333rem;
      box-sizing: border-box;
      padding: 0 0.32rem;
      height: 1.12rem;
      border: 1px solid #ccc !important; /* stylelint-disable-line declaration-no-important */
      input {
        height: 1.12rem;
        background: none;
      }
    }
    .S-Field__inputbox {
      margin-top: 0;
    }
    &.focus::after {
      display: none;
    }
  }
  &__content-agree {
    margin-top: 0.35rem;
  }
  &__content-submit {
    width: 100%;
    height: 1.17333rem;
    margin-top: 0.3rem;
  }

  &__content-error {
    color: #bf4123;
    font-family: SF UI Text;
    font-size: 0.32rem;
    margin-top: 0.2rem;
    span {
      padding-left: 0.05rem;
    }
  }
}
</style>
