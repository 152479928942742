import { ref, computed } from 'vue'
import { isArray  } from '@shein/common-function'

const {
  SiteUID,
} = gbCommonInfo
const isUs = computed(() => !!~SiteUID.indexOf('us'))
export function useFilterList(list) {
  const result = ref([])
  const isEur = computed(() => window?._gb_app_?.$store.getters['newProductDetail/Price/isEur'])
  if(isArray(list)) {
    result.value = list.filter(item => {
      if(isUs.value) {
        return !(item.excludeCountry && item.excludeCountry.includes('us'))
      } else if(isEur.value) {
        return !(item.excludeCountry && item.excludeCountry.includes('eur'))
      } else {
        return true
      }
    })
  }

  return {
    result
  }
}

