<template>
  <div>
    <s-drawer
      :visible="isShow"
      no-header
      type="full"
      direction="rtl"
    >
      <div
        class="mshe-z-transform1 transform-fixed mshe-bg-container shipping-report-drawer"
      >
        <s-title-nav :title="language.SHEIN_KEY_PWA_28923">
          <s-title-nav-item
            slot="preblock"
            class="j-icon-back"
            icon-class="suiiconfont sui_icon_nav_back_24px"
            @click="handleBack"
          />
        </s-title-nav>
        <section class="shipping-report-drawer__content">
          <h2 class="shipping-report-drawer__title">
            {{ language.SHEIN_KEY_PWA_28924 }}
          </h2>
          <div class="shipping-report-drawer__list">
            <ul>
              <li
                v-for="item in reportListFilter"
                :key="item.value"
                class="shipping-report-drawer__list-item"
                :class="{'j-push-history-hash': !item.link}"
                state="reportForm"
                @click="handleClick(item)"
              >
                <div class="shipping-report-drawer__list-item-content">
                  <div class="shipping-report-drawer__list-item-tit">
                    {{ item.title }}
                  </div>
                  <div class="shipping-report-drawer__list-item-desc">
                    {{ item.desc }}
                  </div>
                </div>
                <i
                  class="sidecat-left__iconfont suiiconfont sui_icon_more_right2_16px"
                ></i>
              </li>
            </ul>
          </div>

          <div
            v-if="hasCall"
            class="shipping-report-drawer__call-reservation explain-paragraph"
          >
            {{ language.SHEIN_KEY_PWA_24668 }}
            <span
              class="tip-link"
              @click="openLink(callLink)"
            >
              {{ language.SHEIN_KEY_PWA_24667 }}
            </span>
          </div>

          <div
            v-if="isUs"
            class="shipping-report-drawer__call-reservation explain-paragraph"
          >
            <span>*{{ language.SHEIN_KEY_PWA_25425 }}</span>
            <span
              class="tip-link"
              @click="openLink(ipLink)"
            >
              {{ `www.shein.com${complaintPagePath}` }}
            </span>
          </div>
        </section>
      </div>
    </s-drawer>
    <ReportForm
      v-if="openReportForm"
      v-model="openReportForm"
      :language="props.language"
      :skc="props.skc"
      :radio-group="radioGroup"
      :title="nextTitle"
      :cur-item="curItem"
      :store-store-type="props.storeStoreType"
      :store-code="props.storeCode"
      :business-model="businessModel"
    />
  </div>
</template>

<script>
export default {
  name: 'ReportDrawer'
}
</script>
<script setup>
/**
 * @component 投诉抽屉
 */

import { ref, computed, watch, onUnmounted } from 'vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { stringifyQueryString } from '@shein/common-function'
import {
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
import { useStore } from 'public/src/pages/common/RiskCommon/utils.js'
import ReportForm from './ReportForm'
import { useFilterList } from './hooks'
const {
  langPath,
  host,
  SiteUID,
} = gbCommonInfo
const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => ({})
  },
  skc: {
    type: String,
    default: ''
  },
  hasCall: {
    type: Boolean,
    default: false
  },
  storeStoreType: {
    type: Number,
    default: 0
  },
  storeCode: {
    type: Number,
    default: 0
  },
  businessModel: {
    type: Number,
    default: 0
  },
  isDsaSite: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['input'])
const store = useStore()
const reportFormStatus = computed(() => store.state.newProductDetail.common.reportFormStatus)
const isUs = computed(() => !!~SiteUID.indexOf('us'))

const isShow = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('input', val)
  }
})
watch(
  () => reportFormStatus.value,
  val => {
    if (!props.isDsaSite) {
      openReportForm.value = val
    }
  }
)

const handleBack = () => {
  isShow.value = false
}

const complaintPagePath = ref('/ip-complaint')
const ipLink = `${host}${langPath}${complaintPagePath.value}`
const robotLink = `${host}${langPath}/robot?page=goodsReportPage&page_name=page_goods_detail_report&storecode=${props.storeCode}&business=${props.businessModel}`
const callLink = `${host}${langPath}/user/service-phone?${stringifyQueryString({ queryObj: { report: 1 } })}`

// 列表全数据
const reportList = [
  {
    title: props.language.SHEIN_KEY_PWA_28925,
    desc: props.language.SHEIN_KEY_PWA_28926,
    excludeCountry: ['us'],
    type: 2
  },
  {
    title: props.language.SHEIN_KEY_PWA_28927,
    desc: props.language.SHEIN_KEY_PWA_28928,
    excludeCountry: ['us'],
    type: 1
  },
  {
    title: props.language.SHEIN_KEY_PWA_28929,
    desc: props.language.SHEIN_KEY_PWA_28930,
    type: 3,
    children: [
      {
        title: props.language.SHEIN_KEY_PWA_28935,
        desc: props.language.SHEIN_KEY_PWA_28936,
        value: 3
      },
      {
        title: props.language.SHEIN_KEY_PWA_28937,
        desc: props.language.SHEIN_KEY_PWA_28938,
        value: 4
      },
      {
        title: props.language.SHEIN_KEY_PWA_28931,
        desc: props.language.SHEIN_KEY_PWA_28932,
        value: 1,
        excludeCountry: ['uk', 'eur']
      },
      {
        title: props.language.SHEIN_KEY_PWA_28933,
        desc: props.language.SHEIN_KEY_PWA_28934,
        value: 2,
        excludeCountry: ['uk', 'eur']
      },
      {
        title: props.language.SHEIN_KEY_PWA_28939,
        desc: props.language.SHEIN_KEY_PWA_28940,
        value: 5,
        excludeCountry: ['us']
      }
    ]
  },
  {
    title: props.language.SHEIN_KEY_PWA_28941,
    desc: props.language.SHEIN_KEY_PWA_28942,
    excludeCountry: ['us'],
    link: ipLink
  },
  {
    title: props.language.SHEIN_KEY_PWA_28943,
    desc: props.language.SHEIN_KEY_PWA_28944,
    link: robotLink
  }
]
const { result: reportListFilter } = useFilterList(reportList)

const curItem = ref({})
let timer = null
const openLink = link => {
  markPoint('toNextPageClick', 'public')
  location.href = link
  if(timer) clearTimeout(timer)
  timer = setTimeout(() => {
    handleBack()
  }, 500)
}
onUnmounted(() => {
  clearTimeout(timer)
})
const openReportForm = ref(false)
const radioGroup = ref([])
const nextTitle = ref('')
const handleClick = item => {
  if (item.link) {
    openLink(item.link)
  } else {
    curItem.value = item
    openReportForm.value = true
  }
}
</script>

<style lang="less">
.shipping-report-drawer {
  background-color: #f6f6f6 !important; /* stylelint-disable-line declaration-no-important */
  &__header {
    h4 {
      /* rw:begin */
      font-family: Adieu;
    }
    .sui_icon_nav_back_24px {
      /* rw:begin */
      .font-dpr(
        48px
      ) !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  &__content {
    padding: 0;
    height: 100%;
  }
  &__title {
    color: #000;
    font-family: SF UI Text;
    font-size: 0.37333rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.53rem 0.43rem;
  }
  &__list-item {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.32rem 0.43rem;
    margin-bottom: 0.26667rem;
  }
  &__list-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.26667rem;
  }
  &__list-item-tit {
    color: @sui_color_gray_dark1;
    font-size: 0.37333rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__list-item-desc {
    color: @sui_color_gray_light1;
    font-size: 0.29333rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .explain-paragraph {
    padding: 0 0.32rem;
    .font-dpr(22px);
    line-height: 0.3467rem;
    color: @sui_color_gray_light1;
  }
  .tip-link {
    color: @sui_color_link;
    text-decoration: underline;
  }
  &__call-reservation {
    margin-top: 0.426667rem;
    font-size: 0.29333rem;
  }
  .explain-paragraph-snd {
    margin-top: 0.32rem;
  }

  &__submit-btn {
    background: none;
  }

  .S-Field {
    &::after {
      display: none;
    }
  }
}
</style>
