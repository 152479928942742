<template>
  <div class="statement-drawer">
    <SDrawer
      class="report-drawer-container"
      no-header
      :visible="show"
      :show-close="false"
      close-on-click-modal
      type="multi"
      direction="btt"
      @close-from-mask="show = false"
    >
      <div class="statement-drawer__title">
        {{ template(title, language.SHEIN_KEY_PWA_29045) }}
        <i
          class="statement-drawer__close suiiconfont sui_icon_close_16px_2"
          @click="closeHandleIcon"
        ></i>
      </div>
      <div class="statement-drawer__container">
        <div
          v-for="item in fraudulenceList"
          :key="item.title"
          class="statement-drawer__containerItem"
        >
          <div class="statement-drawer__containerTitle">
            {{ item.title }}
          </div>
          <div class="statement-drawer__containerContent">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </SDrawer>
  </div>
</template>

<script setup>
import { Drawer as SDrawer } from '@shein/sui-mobile'
import { computed } from 'vue'
const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  },
  title: {
    type: String,
    default: ''
  },
  fraudulenceList: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['input'])

const show = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('input', val)
  }
})
const closeHandleIcon = () => {
  show.value = false
}
</script>

<style lang="less" scoped>
.statement-drawer {
  &__close {
    position: absolute;
    top: 50%;
    right: 0.32rem;
    transform: translateY(-50%);
    color: #8a8a8a;
    font-size: 0.42667rem;
  }
  &__title {
    position: relative;
    font-size: 0.42667rem;
    font-weight: 500;
    padding: 0.32rem;
    border-bottom: 0.01333rem solid #e5e5e5;
    text-align: center;
  }
  &__container {
    padding: 0.43rem 0.43rem 0.12rem 0.43rem;
  }
  &__containerItem {
    margin-bottom: 0.53rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__containerTitle {
    font-size: 0.37333rem;
    font-style: normal;
    font-weight: 700;
  }
  &__containerContent {
    margin-top: 0.11rem;
    font-size: 0.32rem;
    font-weight: 400;
  }
}
/deep/ .S-drawer__container {
  border-radius: 0.16rem 0.16rem 0rem 0rem;
}
</style>
