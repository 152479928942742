<template>
  <SDrawer
    :visible="isShow"
    no-header
    type="full"
    direction="rtl"
  >
    <s-title-nav :title="language.SHEIN_KEY_PWA_28923">
      <s-title-nav-item
        slot="preblock"
        icon-class="suiiconfont sui_icon_nav_back_24px"
        @click="handleBack"
      />
    </s-title-nav>
    <div class="report-result">
      <i class="report-result__icon suiiconfont sui_icon_success_40px"></i>
      <div class="report-result__title">
        {{ language.SHEIN_KEY_PWA_28954 }}
      </div>
      <div class="report-result__content">
        {{ language.SHEIN_KEY_PWA_28955 }}
      </div>
      <SButton
        class="report-result__btn"
        width="100%"
        :type="['primary', 'H72PX']"
        @click="handleBack"
      >
        {{ language.SHEIN_KEY_PWA_28956 }}
      </SButton>
    </div>
  </SDrawer>
</template>

<script setup>
import {
  Drawer as SDrawer,
  Button as SButton,
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
import { computed } from 'vue'
const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['input'])

const isShow = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('input', val)
  }
})

const handleBack = () => {
  history.go(-2)
  isShow.value = false
}
</script>

<style lang="less" scoped>
.report-result {
  padding: 0.43rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__icon {
    color: #198055;
    &.suiiconfont {
      font-size: 60px;
    }
  }
  &__title {
    margin-top: 0.21rem;
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-size: 0.45333rem;
    font-weight: 500;
  }
  &__content {
    text-align: center;
    margin-top: 0.43rem;
    margin-bottom: 0.85rem;
    font-size: 0.32rem;
    color: rgba(0, 0, 0, 0.6);
  }
  &__btn {
    height: 1.17333rem;
    font-size: 0.42667rem;
  }
}
</style>
